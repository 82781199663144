
// vue
import { defineComponent } from 'vue';

// vue-toastification
import { provideToast, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// components
import Navbar from '@/components/navbar.vue';
import ClereFooter from '@/components/clere-footer.vue';

export default defineComponent({
    components: {
        Navbar,
        ClereFooter,
    },
    setup() {
        // logic
        provideToast({
            timeout: 10000,
            position: POSITION.BOTTOM_CENTER,
        });
    },
});
