import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        component: () =>
            import(/* webpackChunkName: "core" */ '../views/login.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import(/* webpackChunkName: "core" */ '../views/settings.vue'),
    },
    {
        path: '/data-manager',
        name: 'data-manager',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/data-manager/data-migration.vue'
            ),
    },
    {
        path: '/data-manager/migration-requests',
        name: 'migration-requests',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/data-manager/migration-requests.vue'
            ),
    },
    {
        path: '/data-manager/upload-raster',
        name: 'upload-raster',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/data-manager/upload-raster.vue'
            ),
    },
    {
        path: '/data-manager/download-config',
        name: 'download-config',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/data-manager/download-config.vue'
            ),
    },
    {
        path: '/admin',
        redirect: '/admin/accounts',
    },
    {
        path: '/admin/accounts',
        name: 'admin-accounts',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/admin/accounts.vue'
            ),
    },
    {
        path: '/admin/roles',
        name: 'admin-role',
        component: () =>
            import(/* webpackChunkName: "core" */ '../views/admin/roles.vue'),
    },
    {
        path: '/admin/environments',
        name: 'admin-environments',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/admin/environments.vue'
            ),
    },
    {
        path: '/hub',
        redirect: '/hub/course-schema',
    },
    {
        path: '/hub/course-schema',
        name: 'course-schema',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/hub/course-schema.vue'
            ),
    },
    {
        path: '/hub/mapbox',
        name: 'mapbox',
        component: () =>
            import(/* webpackChunkName: "core" */ '../views/hub/mapbox.vue'),
    },
    {
        path: '/hub/tileserver-config',
        name: 'tileserver-config',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/hub/tileserver-config.vue'
            ),
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/documentation/documentation.vue'
            ),
    },
    {
        path: '/documentation/mosaic',
        name: 'documentation-mosaic',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/documentation/documentation.vue'
            ),
    },
    {
        path: '/documentation/dem',
        name: 'documentation-dem',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/documentation/documentation.vue'
            ),
    },
    {
        path: '/documentation/vector',
        name: 'documentation-vector',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/documentation/documentation.vue'
            ),
    },
    {
        path: '/issue-tracker',
        name: 'issue-tracker',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '../views/issue-tracker/issue-tracker.vue'
            ),
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/dashboard',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to: any, from: any, next: any) => {
    if (
        !store.getters.isLoggedIn &&
        store.state.unauthenticatedRoutes.indexOf(to.path) === -1
    ) {
        next('/');
    } else if (
        store.getters.isLoggedIn &&
        store.state.unauthenticatedRoutes.indexOf(to.path) !== -1
    ) {
        next('/data-manager');
    } else {
        next();
    }
});

export default router;
