
// vue
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// components
import AccountMenu from '@/components/account-menu.vue';
import SubNavbar from '@/components/sub-navbar.vue';

// classes
export class NavbarLink {
    public name!: string;
    public path!: string;
    public permission?: string;
    public subLinks?: NavbarLink[];

    constructor() {
        this.name = '';
    }
}

export default defineComponent({
    components: {
        AccountMenu,
        SubNavbar,
    },
    setup() {
        // hooks
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // properties
        const mainLinks: NavbarLink[] = [
            {
                name: 'Data manager',
                path: '/data-manager',
                subLinks: [
                    {
                        name: 'Data migration',
                        path: '/data-manager',
                    },
                    {
                        name: 'Migration requests',
                        path: '/data-manager/migration-requests',
                    },
                    {
                        name: 'Upload raster',
                        path: '/data-manager/upload-raster',
                    },
                    {
                        name: 'Config File',
                        path: '/data-manager/download-config',
                    },
                ],
            },
            {
                name: 'Issue tracker',
                path: '/issue-tracker',
            },
            {
                name: 'Documentation',
                path: '/documentation',
                subLinks: [
                    {
                        name: 'Overview',
                        path: '/documentation',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'Satellite Imagery',
                        path: '/documentation/mosaic',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'DEM',
                        path: '/documentation/dem',
                        permission: 'canReadDocumentation',
                    },
                    {
                        name: 'Vectors',
                        path: '/documentation/vector',
                        permission: 'canReadDocumentation',
                    },
                ],
            },
            {
                name: 'Admin',
                path: '/admin',
                subLinks: [
                    {
                        name: 'Accounts',
                        path: '/admin/accounts',
                        permission: 'canReadAccount',
                    },
                    {
                        name: 'Roles',
                        path: '/admin/roles',
                        permission: 'canReadRole',
                    },
                    {
                        name: 'Environments',
                        path: '/admin/environments',
                        permission: 'canReadEnvironment',
                    },
                ],
            },
            {
                name: 'Hub',
                path: '/hub',
                subLinks: [
                    {
                        name: 'Course schema',
                        path: '/hub/course-schema',
                        permission: '',
                    },
                    {
                        name: 'Mapbox',
                        path: '/hub/mapbox',
                        permission: '',
                    },
                    {
                        name: 'Tileserver Configs',
                        path: '/hub/tileserver-config',
                        permission: '',
                    },
                ],
            },
        ];

        // computed
        const filteredNavbarLinks = computed(() => {
            return mainLinks.filter((x: NavbarLink) => {
                if (x.subLinks) {
                    return x.subLinks.find((y: NavbarLink) => {
                        if (y.permission) {
                            return (
                                store.state.accountInfo.permissions.indexOf(
                                    y.permission,
                                ) !== -1
                            );
                        } else {
                            return true;
                        }
                    });
                } else {
                    if (x.permission) {
                        return (
                            store.state.accountInfo.permissions.indexOf(
                                x.permission,
                            ) !== -1
                        );
                    } else {
                        return true;
                    }
                }
            });
        });

        // methods
        const routeTo = (navbarLink: NavbarLink) => {
            router.push({ path: navbarLink.path });
        };

        const isSubNavbar = () => {
            const mainLink = mainLinks.find(
                (x) => route.path.indexOf(x.path) === 0,
            );
            return mainLink && mainLink.subLinks;
        };

        return {
            router,
            route,
            mainLinks,
            filteredNavbarLinks,
            routeTo,
            isSubNavbar,
        };
    },
});
