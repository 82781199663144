
// vue
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// models
import { NavbarLink } from '@/components/navbar.vue';

export default defineComponent({
    props: ['mainLinks'],
    setup(props: any) {
        // hooks
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // computed
        const currentNavbarLink = computed(() => {
            return props.mainLinks.find(
                (x: any) => x.path === `/${route.path.split('/')[1]}`,
            );
        });

        const filteredSubLinks = computed(() => {
            if (!currentNavbarLink.value.subLinks) {
                return null;
            }

            return currentNavbarLink.value.subLinks.filter((x: NavbarLink) => {
                if (x.permission) {
                    return (
                        store.state.accountInfo.permissions.indexOf(
                            x.permission,
                        ) !== -1
                    );
                } else {
                    return true;
                }
            });
        });

        // methods
        const routeTo = (navbarLink: NavbarLink) => {
            router.push({ path: navbarLink.path });
        };

        return {
            currentNavbarLink,
            routeTo,
            filteredSubLinks,
        };
    },
});
