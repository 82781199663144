import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a55eeb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-navbar" }
const _hoisted_2 = {
  key: 0,
  class: "sub-links"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentNavbarLink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSubLinks, (subLink, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["sub-link", { 'sub-link-selected': _ctx.$route.path === subLink.path }]),
              key: i,
              onClick: ($event: any) => (_ctx.routeTo(subLink))
            }, _toDisplayString(subLink.name), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}