
// vue
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        // hooks
        const store = useStore();
        const router = useRouter();

        // properties
        const isVisible = ref(false);

        // computed
        const initials = computed(() => {
            const names = store.state.accountInfo.fullName.split(' ');
            return names[0][0] + names[1][0];
        });

        // methods
        const toggleMenu = () => {
            isVisible.value = !isVisible.value;
        };

        const routeTo = (path: string) => {
            isVisible.value = false;
            router.push({ path: path });
        };

        const logOut = () => {
            store.commit('removeAuthToken');
            store.commit('removeAccountInfo');
            router.push({ path: '/' });
        };

        return {
            store,
            isVisible,
            toggleMenu,
            routeTo,
            logOut,
            initials,
        };
    },
});
