import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-wrapper" }
const _hoisted_2 = { class: "router-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_clere_footer = _resolveComponent("clere-footer")!
  const _component_vm_theme = _resolveComponent("vm-theme")!

  return (_openBlock(), _createBlock(_component_vm_theme, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$store.getters.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_navbar, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]),
        (_ctx.$store.getters.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_clere_footer, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}